import { IpfsData, IpfsDataSchema } from "../api/object/ipfs";
import T from "@redwit-commons/utils/typecheck";

export type DocumentBox = {
  id: string;
  name: string;
  pinned: boolean;
  createdAt: string;
  fileCount: number;
  memberList: DocumentBoxMember[];
};

export type DocumentBoxFolder = {
  id: string;
  documentBoxId: string;
  name: string;
  fileCount: number;
};

export enum DocumentBoxMemberRole {
  OWNER = "DocumentBoxMemberRole::OWNER",
  RW = "DocumentBoxMemberRole::RW",
}

export const documentBoxMemberRoleKindList = [
  DocumentBoxMemberRole.OWNER,
  DocumentBoxMemberRole.RW,
];

export type DocumentBoxMember = {
  id: string;
  name: string;
  email: string;
  role: DocumentBoxMemberRole;
  profile: IpfsData;
};

export const DocumentBoxMemberRoleSchema = T.string().withEnum(
  documentBoxMemberRoleKindList
);

export const DocumentBoxMemberSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("role", DocumentBoxMemberRoleSchema.clone())
  .addField("profile", IpfsDataSchema.clone());

export const DocumentBoxSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("pinned", T.boolean())
  .addField("fileCount", T.number())
  .addField("memberList", T.array(DocumentBoxMemberSchema.clone()));

export const DocumentBoxFolderSchema = T.object()
  .addField("id", T.string())
  .addField("documentBoxId", T.string())
  .addField("name", T.string())
  .addField("fileCount", T.number());
