import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum SnackbarStateStatus {
  INIT = "Snackbar::INIT",
  OPENED = "Snackbar::OPENED",
}

export enum SnackbarActionKind {
  TRY_OPEN = "SnackbarAction::TRY_OPEN",
  TRY_CLOSE = "SnackbarAction::TRY_CLOSE",
  TRY_UPDATE_INDICATOR_TYPE = "SnackbarAction::TRY_UPDATE_INDICATOR_TYPE",
}

export enum SnackbarType {
  LOADING = "SnackbarType::LOADING",
  CONFIRM = "SnackbarType::CONFIRM",
  ALERT = "SnackbarType::ALERT",
}

export enum IndicatorType {
  INIT = "SnackbarType::INIT",
  DOWNLOAD = "SnackbarType::DOWNLOAD",
}

export type SnackBarError = never;

export type SnackbarProps = {
  readonly type: SnackbarType;
  readonly msg: string;
  readonly buttonMsg?: string;
  readonly isBackdrop?: boolean;
  readonly duration?: number;
  readonly onClose?: () => void;
  readonly onClickBtn?: () => void;
};

export type IndicatorProps = {
  readonly indicatorType: IndicatorType;
  readonly indicatorPercent: number;
};

export type SnackBarState =
  | ({
      readonly status: SnackbarStateStatus.INIT;
    } & IndicatorProps)
  | ({
      readonly status: SnackbarStateStatus.OPENED;
    } & IndicatorProps &
      SnackbarProps);

export type SnackBarAction =
  | {
      readonly kind: SnackbarActionKind.TRY_UPDATE_INDICATOR_TYPE;
      readonly indicatorType: IndicatorType;
    }
  | ({
      readonly kind: SnackbarActionKind.TRY_OPEN;
      readonly allowIndicator?: boolean;
    } & SnackbarProps)
  | {
      readonly kind: SnackbarActionKind.TRY_CLOSE;
    };

const smid = "SNACKBAR_STATE_MACHINE3";
export type SnackbarStateMachineType = StateMachine3<
  SnackbarStateStatus,
  SnackBarState,
  SnackbarActionKind,
  SnackBarAction,
  SnackBarError
>;
export const snackbarStateMachine: SnackbarStateMachineType = new StateMachine3<
  SnackbarStateStatus,
  SnackBarState,
  SnackbarActionKind,
  SnackBarAction,
  SnackBarError
>(
  smid,
  {
    status: SnackbarStateStatus.INIT,
    indicatorType: IndicatorType.INIT,
    indicatorPercent: 0,
  },
  [
    transition(
      SnackbarStateStatus.INIT,
      SnackbarStateStatus.OPENED,
      SnackbarActionKind.TRY_OPEN
    ),
    transition(
      SnackbarStateStatus.OPENED,
      SnackbarStateStatus.OPENED,
      SnackbarActionKind.TRY_OPEN
    ),
    transition(
      SnackbarStateStatus.OPENED,
      SnackbarStateStatus.INIT,
      SnackbarActionKind.TRY_CLOSE
    ),
    transition(
      SnackbarStateStatus.INIT,
      SnackbarStateStatus.INIT,
      SnackbarActionKind.TRY_CLOSE
    ),
    transition(
      SnackbarStateStatus.INIT,
      SnackbarStateStatus.INIT,
      SnackbarActionKind.TRY_UPDATE_INDICATOR_TYPE
    ),
    transition(
      SnackbarStateStatus.OPENED,
      SnackbarStateStatus.OPENED,
      SnackbarActionKind.TRY_UPDATE_INDICATOR_TYPE
    ),
  ]
);

export type DispatchSnackbarAction = Dispatch<
  StateMachineAction<
    SnackbarStateStatus,
    SnackBarState,
    SnackbarActionKind,
    SnackBarAction,
    SnackBarError
  >
>;

export default mkReducer<
  SnackbarStateStatus,
  SnackBarState,
  SnackbarActionKind,
  SnackBarAction,
  SnackBarError
>(snackbarStateMachine);

export const doSnackbarAction = (
  dispatch: DispatchSnackbarAction,
  nextAction: SnackBarAction,
  onResolve: () => void = () => {},
  onReject: (err: SnackBarError | InternalError) => void = () => {}
) => {
  dispatch(snackbarStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doSnackbarActionAsync = (
  dispatch: DispatchSnackbarAction,
  nextAction: SnackBarAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(snackbarStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetSnackbar = (dispatch: DispatchSnackbarAction) => {
  dispatch(snackbarStateMachine.newResetAction());
};
