import {
  IAdminTransferProject,
  IAdminUserModify,
  IAssignRegisterEmail,
  IResendAuthEmail,
} from "@goono-commons/api/request/admin";
import { IGetUserLogs } from "@goono-commons/api/request/user_log";
import {
  OAdminUserModify,
  OAdminUserModifyPending,
  OGetGraphsData,
  validateOAdminUserModify,
  validateOAdminUserModifyPending,
  validateOGetGraphsData,
} from "@goono-commons/api/response/admin";
import {
  OGetProjectActivity,
  OGetUserLogs,
  validateOGetProjectActivity,
  validateOGetUserLogs,
} from "@goono-commons/api/response/user_log";
import {
  AdminActivityQueryParams,
  AdminCreateOrganizationAdminDto,
  AdminCreateOrganizationDto,
  AdminCreateWorkspaceDto,
  AdminWorkspaceBulkOperationQueryOptions,
  AdminOrganizationAdmin,
  AdminOrganizationSummary,
  AdminUpdateOrganizationAdminDto,
  AdminUpdateOrganizationDto,
  AdminUpdateWorkspaceDto,
  AdminUsersQueryOptions,
  AdminWorkspace,
  AdminWorkspacesQueryOptions,
  CreateWorkspaceResult,
  GetAdminOrganizationAdmins,
  GetAdminOrganizations,
  GetAdminUsers,
  GetAdminWorkspaceActivityLog,
  GetAdminWorkspaces,
  UpdateAdminWorkspaceMembersParams,
  validateCreateWorkspace,
  validateGetAdminOrganization,
  validateGetAdminOrganizationAdmins,
  validateGetAdminOrganizationOwnAdmins,
  validateGetAdminOrganizationOwnWorkspaces,
  validateGetAdminOrganizations,
  validateGetAdminUsers,
  validateGetAdminWorkspace,
  validateGetAdminWorkspaceActivityLog,
  validateGetAdminWorkspaces,
  validateGetUserOwnerDocumentBoxList,
  AdminWorkspaceMembersQueryOptions,
  AdminWorkspaceMemberAccessLogCsvQuery,
  CSVResult,
  validateGetCSVFile,
  validateGetBulkUpdateTasks,
  AdminGetBulkUpdateTaskObject,
} from "@goono-commons/v3/goonoAdmin";
import {
  OGetAdminProjectSummaryDTO,
  OGetAdminUserOwnerProjectSummaryDTOList,
  validateOGetAdminProjectSummaryDTO,
  validateOGetAdminUserOwnerProjectSummaryDTOList,
} from "@goono-commons/v3/response/project";
import { Workspace, WorkspacePlan } from "@goono-commons/v3/workspace";
import {
  doAPIDeleteRequest,
  doAPIGetRequest,
  doAPIPostRequest,
  doAPIPutRequest,
} from "./api";
import {
  FileUploadActivitySummary,
  ResearcherUploadHistory,
  validateFileUploadActivitySummary,
  validateResearcherUploadHistory,
} from "@goono-commons/v3/adminDashboard";
import {
  IGetFileUploadActivitySummary,
  IResearcherUploadHistory,
} from "@goono-commons/v3/request/adminDashboard";
import { IAdminGetProjectActivity } from "@goono-commons/v3/request/activityLog";
import { DocumentBox } from "@goono-commons/dataRoom/documentBox";
import {
  OGetAdminWorkspaceMembers,
  validateOGetAdminWorkspaceMembers,
} from "@goono-commons/v3/response/admin";

/** ---- 사용자 & 워크스페이스 관련 ---- */

/** 전체 사용자 조회 */
export const getAllUsers = async (params: {
  token: string;
  queries: AdminUsersQueryOptions;
}): Promise<GetAdminUsers> => {
  const obj = await doAPIGetRequest(
    `/admin/users`,
    params.token,
    params.queries
  );
  return validateGetAdminUsers(obj);
};

/** 전체 워크스페이스 조회 */
export const getAllWorkspaces = async (params: {
  token: string;
  queries: AdminWorkspacesQueryOptions;
}): Promise<GetAdminWorkspaces> => {
  const obj = await doAPIGetRequest(
    `/admin/workspaces`,
    params.token,
    params.queries
  );
  return validateGetAdminWorkspaces(obj);
};

/** 워크스페이스 단일조회 */
export const getWorkspace = async (params: {
  token: string;
  workspaceId: string;
}): Promise<AdminWorkspace> => {
  const obj = await doAPIGetRequest(
    `/admin/workspace?workspaceId=${params.workspaceId}`,
    params.token
  );
  return validateGetAdminWorkspace(obj);
};

/** 워크스페이스 멤버조회 */
export const getWorkspaceMembers = async (params: {
  token: string;
  workspaceId: string;
  queries: AdminWorkspaceMembersQueryOptions;
}): Promise<OGetAdminWorkspaceMembers> => {
  const obj = await doAPIGetRequest(
    `/admin/${params.workspaceId}/members`,
    params.token,
    params.queries
  );
  return validateOGetAdminWorkspaceMembers(obj);
};

/** 워크스페이스 활동 내역 조회 */
export const getWorkspaceActivityLog = async (params: {
  token: string;
  workspaceId: string;
  queries: AdminActivityQueryParams;
}): Promise<GetAdminWorkspaceActivityLog> => {
  const obj = await doAPIGetRequest(
    `/admin/${params.workspaceId}/activities`,
    params.token,
    params.queries
  );
  return validateGetAdminWorkspaceActivityLog(obj);
};

export const getWorkspaceSummaryCSV = async (params: {
  token: string;
  queries: AdminWorkspaceBulkOperationQueryOptions;
}): Promise<CSVResult> => {
  const ret = await doAPIGetRequest(
    `/admin/workspaces/csv`,
    params.token,
    params.queries
  );
  return validateGetCSVFile(ret);
};

/** 연구노트 csv 파일 다운로드 */
export const getProjectsCSV = async (params: {
  token: string;
  queries: AdminWorkspaceBulkOperationQueryOptions;
}): Promise<CSVResult> => {
  const ret = await doAPIGetRequest(
    `/admin/workspaces/projects/csv`,
    params.token,
    params.queries
  );
  return validateGetCSVFile(ret);
};

export const getDocumentBoxCSV = async (params: {
  token: string;
  queries: AdminWorkspaceBulkOperationQueryOptions;
}): Promise<CSVResult> => {
  const ret = await doAPIGetRequest(
    `/admin/workspaces/documentBoxes/csv`,
    params.token,
    params.queries
  );
  return validateGetCSVFile(ret);
};

export const getDataRoomPaymentSummaryCSV = async (params: {
  token: string;
}): Promise<string> => {
  const obj = (await doAPIGetRequest(
    `/admin/workspaces/dataRoom/payment/csv`,
    params.token
  )) as Blob;
  return obj.text();
};

export const getWorkspaceMemberAccessLogCSV = async (params: {
  token: string;
  queries: AdminWorkspaceMemberAccessLogCsvQuery;
}): Promise<CSVResult> => {
  const ret = await doAPIGetRequest(
    `/admin/workspaces/members/log/csv`,
    params.token,
    params.queries
  );
  return validateGetCSVFile(ret);
};

/** 워크스페이스 멤버 추가 & 권한 변경 */
export const updateWorkspaceMembers = async (params: {
  token: string;
  workspaceId: string;
  reqBody: UpdateAdminWorkspaceMembersParams;
}) => {
  await doAPIPostRequest(
    `/admin/${params.workspaceId}/members`,
    params.token,
    params.reqBody
  );
};

/** 워크스페이스 소유자 변경 */
export const updateWorkspaceOwner = async (params: {
  token: string;
  workspaceId: string;
  reqBody: { userId: string };
}) => {
  await doAPIPutRequest(
    `/admin/${params.workspaceId}/owner`,
    params.token,
    params.reqBody
  );
};

/** 워크스페이스 멤버 삭제 */
export const resignWorkspaceMember = async (params: {
  token: string;
  workspaceId: string;
  userIds: string; // string in format of JSON array (ex. '["a","b","c"]')
}) => {
  await doAPIDeleteRequest(
    `/admin/${params.workspaceId}/member?userIds=${params.userIds}`,
    params.token
  );
};

/** 워크스페이스 생성 */
export const createWorkspace = async (params: {
  token: string;
  reqBody: AdminCreateWorkspaceDto;
}): Promise<CreateWorkspaceResult> => {
  const obj = await doAPIPostRequest(
    `/admin/workspace`,
    params.token,
    params.reqBody
  );
  const ret = validateCreateWorkspace(obj);
  return ret;
};

/** 워크스페이스 수정 */
export const updateWorkspace = async (params: {
  token: string;
  reqBody: AdminUpdateWorkspaceDto;
}) => {
  await doAPIPutRequest(`/admin/workspace`, params.token, params.reqBody);
};

/** 워크스페이스 삭제 */
export const deleteWorkspace = async (params: {
  token: string;
  workspaceId: string;
}) => {
  await doAPIDeleteRequest(
    `/admin/workspace?workspaceId=${params.workspaceId}`,
    params.token
  );
};

/** 워크스페이스 플랜 수정 & 갱신 */
export const updateWorkspacePlan = async (params: {
  token: string;
  workspaceId: string;
  reqBody: WorkspacePlan;
}) => {
  await doAPIPutRequest(
    `/admin/${params.workspaceId}/plan`,
    params.token,
    params.reqBody
  );
};

/** 워크스페이스 플랜 일괄 갱신 */
export const bulkUpdateWorkspacePlan = async (params: {
  token: string;
  queries: AdminWorkspacesQueryOptions;
  reqBody: Partial<WorkspacePlan>;
}) => {
  let endpoint = "/admin/workspace/plan/bulk";
  const listParams: string[] = [];
  for (const key in params.queries) {
    const entry = (params.queries as { [key: string]: string })[key];

    if (
      typeof entry === "string" ||
      typeof entry === "number" ||
      typeof entry === "boolean"
    ) {
      const param = `${encodeURIComponent(key)}=${encodeURIComponent(
        entry.toString()
      )}`;
      listParams.push(param);
    }
  }
  if (listParams.length > 0) {
    const queryString = listParams.join("&");
    endpoint += `?${queryString}`;
  }

  await doAPIPutRequest(endpoint, params.token, params.reqBody);
};

/** 워크스페이스 멤버 일괄 추가 / 권한 변경 */
export const bulkUpdateWorkspaceMembers = async (params: {
  token: string;
  queries: AdminWorkspacesQueryOptions;
  reqBody: UpdateAdminWorkspaceMembersParams;
}) => {
  let endpoint = "/admin/workspace/member/bulk";
  const listParams: string[] = [];
  for (const key in params.queries) {
    const entry = (params.queries as { [key: string]: string })[key];

    if (
      typeof entry === "string" ||
      typeof entry === "number" ||
      typeof entry === "boolean"
    ) {
      const param = `${encodeURIComponent(key)}=${encodeURIComponent(
        entry.toString()
      )}`;
      listParams.push(param);
    }
  }
  if (listParams.length > 0) {
    const queryString = listParams.join("&");
    endpoint += `?${queryString}`;
  }

  await doAPIPutRequest(endpoint, params.token, params.reqBody);
};

/** 연구노트 소유자 변경 */
export const transferProjectOwner = async (
  token: string,
  args: IAdminTransferProject
) => {
  await doAPIPostRequest(`/admin/project/transfer`, token, args);
};

/** 유저가 소유자 권한인 연구노트 조회 */
export const getUserOwnerProjectList = async (
  token: string,
  userId: string,
  workspaceId: string
): Promise<OGetAdminUserOwnerProjectSummaryDTOList> => {
  const ret = await doAPIGetRequest(
    `admin/user/${userId}/project?workspaceId=${workspaceId}`,
    token
  );
  return validateOGetAdminUserOwnerProjectSummaryDTOList(ret);
};

/** 변경 대기상태 task 조회 */
export const getPendingTasks = async (
  token: string
): Promise<OAdminUserModifyPending> => {
  const obj = await doAPIGetRequest(`/admin/user/modify/pending`, token);
  const ret = validateOAdminUserModifyPending(obj);
  return ret;
};

/** 유저 계정 업데이트 */
export const updateUserAccount = async (
  token: string,
  args: IAdminUserModify
): Promise<OAdminUserModify> => {
  const obj = await doAPIPostRequest(`/admin/user/modify`, token, args);
  const ret = validateOAdminUserModify(obj);
  return ret;
};

/** 유저 이메일 인증처리 */
export const assignPendingEmailUser = async (
  token: string,
  args: IAssignRegisterEmail
) => {
  await doAPIPostRequest(`/admin/assign/register/email`, token, args);
};

/** 비밀번호 변경메일 발송 */
export const resendAuthEmail = async (
  token: string,
  args: IResendAuthEmail
) => {
  await doAPIPostRequest(`/admin/resend/auth/email`, token, args);
};

/** ---- 조직 관련 ---- */

/** 전체 조직 조회 */
export const getAllOrganizations = async (params: {
  token: string;
  queries: { limit: number; offset: number };
}): Promise<GetAdminOrganizations> => {
  const obj = await doAPIGetRequest(
    `/admin/organizations`,
    params.token,
    params.queries
  );
  return validateGetAdminOrganizations(obj);
};

/** 단일 조직 조회 */
export const getOrganization = async (params: {
  token: string;
  query: { organizationId: string };
}): Promise<AdminOrganizationSummary> => {
  const obj = await doAPIGetRequest(
    `/admin/organization`,
    params.token,
    params.query
  );
  return validateGetAdminOrganization(obj);
};

/** 단일 조직 내 조직 관리자 조회 */
export const getOrganizationAdmins = async (params: {
  token: string;
  organizationId: string;
}): Promise<AdminOrganizationAdmin[]> => {
  const obj = await doAPIGetRequest(
    `/admin/organization/${params.organizationId}/admins`,
    params.token
  );
  return validateGetAdminOrganizationOwnAdmins(obj);
};

/** 단일 조직 내 워크스페이스 조회 */
export const getOrganizationWorkspaces = async (params: {
  token: string;
  organizationId: string;
}): Promise<Workspace[]> => {
  const obj = await doAPIGetRequest(
    `/admin/organization/${params.organizationId}/workspaces`,
    params.token
  );
  return validateGetAdminOrganizationOwnWorkspaces(obj);
};

/** 전체 조직 관리자 조회 */
export const getAllOrganizationAdmins = async (params: {
  token: string;
  queries: { limit: number; offset: number; search?: string };
}): Promise<GetAdminOrganizationAdmins> => {
  const obj = await doAPIGetRequest(
    `/admin/organization/admins`,
    params.token,
    params.queries
  );
  return validateGetAdminOrganizationAdmins(obj);
};

/** 조직 생성 */
export const createOrganization = async (params: {
  token: string;
  reqBody: AdminCreateOrganizationDto;
}) => {
  await doAPIPostRequest(`/admin/organization`, params.token, params.reqBody);
};

/** 조직 수정 */
export const updateOrganization = async (params: {
  token: string;
  reqBody: AdminUpdateOrganizationDto;
}) => {
  await doAPIPutRequest(`/admin/organization`, params.token, params.reqBody);
};

/** 조직 삭제 */
export const deleteOrganization = async (params: {
  token: string;
  organizationId: string;
}) => {
  await doAPIDeleteRequest(
    `/admin/organization?organizationId=${params.organizationId}`,
    params.token
  );
};

/** 조직 내 조직관리자 추가 */
export const assignOrganizationAdmins = async (params: {
  token: string;
  organizationId: string;
  reqBody: { adminIds: string[] };
}) => {
  await doAPIPostRequest(
    `/admin/organization/${params.organizationId}/admin`,
    params.token,
    params.reqBody
  );
};

/** 조직 내 조직관리자 삭제 */
export const resignOrganizationAdmins = async (params: {
  token: string;
  organizationId: string;
  adminIds: string; // string in format of JSON array (ex. '["a","b","c"]')
}) => {
  await doAPIDeleteRequest(
    `/admin/organization/${params.organizationId}/admin?adminIds=${params.adminIds}`,
    params.token
  );
};

/** 조직 내 워크스페이스 추가 */
export const assignOrganizationWorkspaces = async (params: {
  token: string;
  organizationId: string;
  reqBody: { workspaceIds: string[] };
}) => {
  await doAPIPostRequest(
    `/admin/organization/${params.organizationId}/workspace`,
    params.token,
    params.reqBody
  );
};

/** 조직 내 워크스페이스 삭제 */
export const resignOrganizationWorkspaces = async (params: {
  token: string;
  organizationId: string;
  workspaceIds: string; // string in format of JSON array (ex. '["a","b","c"]')
}) => {
  await doAPIDeleteRequest(
    `/admin/organization/${params.organizationId}/workspace?workspaceIds=${params.workspaceIds}`,
    params.token
  );
};

/** 조직관리자 생성 */
export const createOrganizationAdmin = async (params: {
  token: string;
  reqBody: AdminCreateOrganizationAdminDto;
}) => {
  await doAPIPostRequest(
    `/admin/organization/admin`,
    params.token,
    params.reqBody
  );
};

/** 조직관리자 수정 */
export const updateOrganizationAdmin = async (params: {
  token: string;
  reqBody: AdminUpdateOrganizationAdminDto;
}) => {
  await doAPIPutRequest(
    `/admin/organization/admin`,
    params.token,
    params.reqBody
  );
};

/** 조직관리자 삭제 */
export const deleteOrganizationAdmin = async (params: {
  token: string;
  adminId: string;
}) => {
  await doAPIDeleteRequest(
    `/admin/organization/admin?adminId=${params.adminId}`,
    params.token
  );
};

/** ---- 기타 (추후 삭제예정) ---- */
export const getAllGraphs = async (token: string): Promise<OGetGraphsData> => {
  const obj = await doAPIGetRequest(`admin/user/graph`, token);
  const ret = validateOGetGraphsData(obj);
  return ret;
};

export const getUserLogs = async (
  token: string,
  args: IGetUserLogs
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(`user_logs`, token, args);
  const ret = validateOGetUserLogs(res);
  return ret;
};

export const getWorkspaceFileUploadActivitySummary = async (
  token: string,
  workspaceId: string,
  args: IGetFileUploadActivitySummary
): Promise<FileUploadActivitySummary> => {
  const res = await doAPIGetRequest(
    `/admin/dashboard/${workspaceId}/researcher/upload/statistics`,
    token,
    args
  );

  const ret = validateFileUploadActivitySummary(res);
  return ret;
};

export const getResearcherUploadHistory = async (
  token: string,
  workspaceId: string,
  researcherId: string,
  args: IResearcherUploadHistory
): Promise<ResearcherUploadHistory> => {
  const res = await doAPIGetRequest(
    `/activity/upload/statics/${researcherId}/${workspaceId}`,
    token,
    args
  );

  const ret = validateResearcherUploadHistory(res);
  return ret;
};

export const getWorkspaceActivity = async (
  token: string,
  workspaceId: string,
  args: IAdminGetProjectActivity
): Promise<OGetProjectActivity> => {
  const res = await doAPIGetRequest(
    `/activity/list/${workspaceId}`,
    token,
    args
  );
  const ret = validateOGetProjectActivity(res);
  return ret;
};

export const getAllProjectSummaryDTO = async (
  token: string,
  workspaceId: string
): Promise<OGetAdminProjectSummaryDTO> => {
  const res = await doAPIGetRequest(
    `/projects/manager/summary/list/${workspaceId}`,
    token
  );
  const ret = validateOGetAdminProjectSummaryDTO(res);
  return ret;
};

export const getUserOwnerDocumentBoxList = async (params: {
  token: string;
  workspaceId: string;
  userId: string;
}): Promise<DocumentBox[]> => {
  const ret = await doAPIGetRequest(
    `/admin/documentBoxes/${params.workspaceId}/${params.userId}`,
    params.token
  );
  return validateGetUserOwnerDocumentBoxList(ret);
};

export const transferDocumentBoxOwner = async (params: {
  token: string;
  reqBody: { userId: string; documentBoxIds: string[] };
}) => {
  return await doAPIPutRequest(
    `/admin/documentBoxes/owner`,
    params.token,
    params.reqBody
  );
};

export const getBulkUpdateTasks = async (
  token: string
): Promise<AdminGetBulkUpdateTaskObject[]> => {
  const ret = await doAPIGetRequest(`/admin/task/bulk/current`, token);
  return validateGetBulkUpdateTasks(ret);
};
